import { DynamicEnvironment } from './dynamic-enviroments';
class Enviroment extends DynamicEnvironment {
  public production: boolean;
  public appVersion: string;
  public trendPrioritizerUrl: string;
  public trendDetectionUrl: string;
  constructor() {
    super();

    this.appVersion = require('../../package.json').version + '-stage';
    this.production = false;
    this.trendPrioritizerUrl = 'https://api-stage.anyidea.ai/ai-prioritizer/';
    this.trendDetectionUrl = 'https://api-stage.anyidea.ai/ai-trend-detection/';
  }
}

export const environment = new Enviroment();
