import { Component } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Angular Starter';

  constructor(private translate: TranslocoService) {
    translate.setActiveLang(getBrowserLang());
  }
}
